.app-header {
  align-items: center;
  background-color: #f6f6f6;
  color: #e2001a;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  padding-top: 20px;
}

.app-header-title {
  font-size: inherit;
  font-weight: 400;
  margin: 20px;
}

.app-header-login {
  margin: 20px;
}

.app-header-links {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.app-header-link {
  color: #444;
  padding: 16px;
  text-decoration: none;
}

.welcome {
  font-size: 20px;
  margin: 60px auto;
  max-width: 60%;
}

.button {
  margin: 20px;
}

.code {
  text-align: left;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 40rem;
}
